
import { defineComponent } from 'vue';
import PageContent from '../PageContent.vue';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { Bar, Pie } from 'vue-chartjs';
import { lightTheme } from '@/plugins/vuetify';
import OrderService from '../../services/OrderService';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export default defineComponent({
	name: 'HomeView',
	data() {
		return {
			loadingMonthlyChart: false,
			loadingStatusChart: false,
			monthlyData: {
				labels: [],
				datasets: [
					{
						label: 'Total',
						backgroundColor: lightTheme.colors.primary,
						data: []
					}
				]
			},
			statusData: {
				labels: [],
				datasets: [
					{
						backgroundColor: [
							lightTheme.colors.primary,
							lightTheme.colors.grey,
						],
						data: []
					}
				]
			},
			monthlyChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					}
				}
			},
			statusChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						position: 'bottom'
					}
				}
			},
		}
	},
	methods: {
		loadMonthlyData() {
			this.loadingMonthlyChart = true;
			OrderService.chartOrdersByMonth()
				.then(res => {
					this.loadingMonthlyChart = false;
					this.monthlyData.labels = res.data.map((item: any) => item.month);
					this.monthlyData.datasets[0].data = res.data.map((item: any) => item.count);
				})
				.catch(err => {
					this.loadingMonthlyChart = false;
				});
		},
		loadStatusData() {
			this.loadingStatusChart = true;
			OrderService.chartOrdersByStatus()
				.then(res => {
					this.loadingStatusChart = false;
					this.statusData.labels = res.data.map((item: any) => item.status);
					this.statusData.datasets[0].data = res.data.map((item: any) => item.count);
				})
				.catch(err => {
					this.loadingStatusChart = false;
				});
		},
	},
	created() {
		this.loadMonthlyData();
		this.loadStatusData();
	},
	components: {
		PageContent,
		Bar,
		Pie
	}
});
