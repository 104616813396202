import axios from 'axios';
import AuthService from './AuthService';
import router from '@/router';

const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json'
  }
});

client.interceptors.request.use(
  config => {
    const token = AuthService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }
);

client.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    if (error.response.status === 401) {
      AuthService.logout();
      router.push('/login');
    }
    throw error.response;
  }
);

export default client;