import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("form", {
    class: "searchbar",
    id: "searchbar",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onSubmit()), ["prevent"]))
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "input-search",
      placeholder: "Buscar",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput()))
    }, null, 544), [
      [_vModelText, _ctx.model]
    ]),
    (!_ctx.isClean)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          type: "button",
          class: "mr-2",
          size: "x-small",
          icon: "mdi-close",
          variant: "plain",
          density: "comfortable",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearInput()))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_btn, {
      type: "submit",
      icon: "mdi-magnify",
      variant: "plain",
      density: "comfortable",
      form: "searchbar",
      disabled: !_ctx.isChanged
    }, null, 8, ["disabled"])
  ], 32))
}