import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f7329f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-login" }
const _hoisted_2 = { class: "login-logo" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_card, {
        class: "container",
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", { src: _ctx.logo }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("form", {
            class: "login-form",
            id: "form-login",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"]))
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_v_text_field, {
                type: "email",
                label: "Email",
                variant: "underlined",
                color: "primary",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_v_text_field, {
                type: "password",
                label: "Senha",
                variant: "underlined",
                color: "primary",
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_v_btn, {
              type: "submit",
              size: "x-large",
              color: "primary",
              form: "form-login",
              disabled: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Login")
              ]),
              _: 1
            }, 8, ["disabled"])
          ], 32)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _createVNode(_component_v_snackbar, {
      timeout: 4500,
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.snackbar) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}