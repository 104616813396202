
import ConfirmBox from '@/components/ConfirmBox.vue';
import { DateHelper } from '@/helpers/DateHelper';
import OrderService from '@/services/OrderService';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'OrderDialog',
    data() {
        return {
            loading: false,
            snackbarTxt: '',
            snackbarState: false,
            snackbarColor: '',
            confirmBoxCertificate: false,
            invoice: '',
        };
    },
    props: {
        show: Boolean,
        order: {} as any,
    },
    methods: {
        formatDatetime(date: string) {
            return DateHelper.formatDatetime(date);
        },
        sendCertificate() {
            if (!this.invoice) {
                this.snackbarState = true;
                this.snackbarTxt = 'Informe o número da transação!';
                this.snackbarColor = 'error';
                return;
            }
            this.loading = true;
            this.confirmBoxCertificate = false;
            const {file_hash, email, created_at } = this.order;
            OrderService.sendCertificate(file_hash, email, this.invoice, created_at)
                .then(res => {
                    this.loading = false;
                    this.snackbarState = true;
                    this.snackbarTxt = 'Certificado enviado com sucesso!';
                    this.snackbarColor = 'success';
                })
                .catch(res => {
                    this.loading = false;
                    this.snackbarState = true;
                    this.snackbarTxt = res.data.error;
                    this.snackbarColor = 'error';
                });
        },
        confirmSendCertificate() {
            if (!this.order.paid_at) {
                this.snackbarState = true;
                this.snackbarTxt = 'Não foi possível enviar certificado pois o pedido não foi pago!';
                this.snackbarColor = 'error';
                return;
            }
            this.confirmBoxCertificate = true;
        },
        cancelSendCertificate() {
            this.confirmBoxCertificate = false;
        }
    },
    updated() {
        this.snackbarState = false;
        this.invoice = String(this.order.transaction?.invoice ?? '');
    },
    components: {
		ConfirmBox
	}
});
