import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    value: _ctx.model,
    width: "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "primary",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Cancelar ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                color: "primary",
                variant: "tonal",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Confirmar ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["value"]))
}