
import AuthService from '@/services/AuthService';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'PageContent',
	props: {
		hideFooter: Boolean,
		padding: String,
		title: String,
		breadcrumbs: Object({ 
			title: String,
			disabled: Boolean,
			route: String
		})
	},
	data() {
		return {
			drawer: true,
			styleObj: {
				padding: this.padding ?? '20px'
			},
			classObj: {
				'hide-footer': this.hideFooter
			}
		}
	},
	methods: {
		scrollTop() {
			document.getElementById('page-content')
				?.scrollTo({ top: 0, behavior: 'smooth' });
		},
		logout() {
			AuthService.logout()
				.then(res => {
					this.$router.push('/login');
				})
		}
	}
});
