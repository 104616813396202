import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Pie = _resolveComponent("Pie")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_PageContent = _resolveComponent("PageContent")!

  return (_openBlock(), _createBlock(_component_PageContent, { "hide-footer": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "7" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                title: "Pedidos no último ano",
                loading: _ctx.loadingMonthlyChart
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, { class: "monthly-chart" }, {
                    default: _withCtx(() => [
                      (!_ctx.loadingMonthlyChart)
                        ? (_openBlock(), _createBlock(_component_Bar, {
                            key: 0,
                            data: _ctx.monthlyData,
                            options: _ctx.monthlyChartOptions
                          }, null, 8, ["data", "options"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "5" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                title: "Pedidos por status",
                loading: _ctx.loadingStatusChart
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, { class: "monthly-chart" }, {
                    default: _withCtx(() => [
                      (!_ctx.loadingStatusChart)
                        ? (_openBlock(), _createBlock(_component_Pie, {
                            key: 0,
                            data: _ctx.statusData,
                            options: _ctx.statusChartOptions
                          }, null, 8, ["data", "options"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}