export const DateHelper = {
	formatDate: (dateString: string) => {
		const date = new Date(dateString);
		return new Intl.DateTimeFormat('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short'
		}).format(date);
	},
	formatDatetime: (dateString: string) => {
		const date = new Date(dateString);
		return new Intl.DateTimeFormat('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		}).format(date);
	}
}