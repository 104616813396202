import http from './HttpClient';

class OrderService {
	getAll(params?: any) {
		return http.get('admin/orders', { params });
	}

	sendCertificate(hash: string, email: string, invoice: string, createdAt: string) {
		return http.post('v3/eduzz/delivery/custom', {
			edz_produtor_email: process.env.VUE_APP_EDZ_PRODUTOR_EMAIL,
			edz_produtor_cod: process.env.VUE_APP_EDZ_PRODUTOR_COD,
			edz_cli_apikey: process.env.VUE_APP_EDZ_CLI_APIKEY,
			edz_fat_status: process.env.VUE_APP_EDZ_FAT_STATUS,
			edz_gtr_param1: hash,
			edz_cli_email: email,
			edz_fat_cod: invoice,
			edz_fat_dtcadastro: createdAt
		});
	}

	chartOrdersByMonth() {
		return http.get('admin/orders/chart/month');
	}

	chartOrdersByStatus() {
		return http.get('admin/orders/chart/status');
	}
}

export default new OrderService();