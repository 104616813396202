
import { defineComponent } from 'vue';
import OrderService from '../../services/OrderService';
import PageContent from '../PageContent.vue';
import OrderDialog from './OrderDialog.vue';
import Searchbar from '../../components/Searchbar.vue';
import { DateHelper } from '@/helpers/DateHelper';

export default defineComponent({
  name: "OrderListView",
  data() {
    return {
      title: 'Pedidos',
      breadcrumbs: [
        {
          title: 'Início',
          disabled: false,
          route: '/home',
        },
        {
          title: 'Pedidos',
          disabled: true
        },
      ],
      loading: true,
      orders: [] as any,
      total: 0,
      currentPage: 1,
      limit: 30,
      numberPages: 0,
      headers: [
        { title: 'Data/Hora', key: 'created_at' },
        { title: 'Título', key: 'title' },
        { title: 'E-mail', key: 'email' },
        { title: 'Transação', key: 'transaction.protocol' },
        { title: 'Pago', key: 'paid_at', align: 'center', sortable: false },
        { title: '', key: 'actions', sortable: false }
      ],
      orderDialog: false,
      currentIndex: -1,
      currentOrder: {} as any,
      search: '',
      snackbarTxt: '',
      snackbarState: false,
      snackbarColor: '',
    };
  },
  methods: {
    loadOrders() {
      this.loading = true;
      const params = {
        page: this.currentPage,
        limit: this.limit,
        search: this.search
      };
      OrderService.getAll(params)
        .then(res => {
          this.loading = false;
          this.orders = res.data.items;
          this.total = res.data.total;
          this.currentPage = res.data.current_page;
          this.numberPages = Math.ceil(this.total / this.limit);
        })
        .catch(e => {
          this.loading = false;
        });
    },
    formatDatetime(date: string) {
      return DateHelper.formatDatetime(date);
    },
    changeCurrentPage(page: number) {
      PageContent.methods?.scrollTop();
      this.currentPage = page;
      this.loadOrders();
    },
    openOrder(order: any) {
      this.currentIndex = this.orders.indexOf(order);
      this.currentOrder = Object.assign({}, order);
      this.orderDialog = true;
    },
    closeOrderDialog() {
      this.orderDialog = false;
      this.currentIndex = -1;
    },
    orderSearch(value: string) {
      this.search = value;
      this.currentPage = 1;
      this.loadOrders();
    },    
  },
  created() {
    this.loadOrders();
  },
  components: {
    PageContent,
    OrderDialog,
    Searchbar
}
});
