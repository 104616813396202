
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BaseLayout',
	data() {
		return {
			drawer: true,
			logo: require('@/assets/images/logo.webp'),
			items: [
				{ title: 'Início', icon: 'mdi-view-dashboard', value: 'home', to: '/home' },
				{ title: 'Pedidos', icon: 'mdi-account-box', value: 'orders', to: '/orders' },
			]
		}
	},
});
