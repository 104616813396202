
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'Search-bar',
	data() {
		return {
			model: '',
			lastSearch: '',
			isClean: true,
			isChanged: false,
		}
	},
	methods: {
		clearInput() {
			this.model = '';
			this.onInput();
			this.onSubmit();
		},
		onInput() {
			this.isClean = this.model === '';
			this.isChanged = this.model !== this.lastSearch;
		},
		onSubmit() {
			if (this.isChanged) {
				this.$emit('search', this.model);
				this.lastSearch = this.model;
				this.isChanged = false;
			}
		}
	}
})
