import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ConfirmBox = _resolveComponent("ConfirmBox")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    value: _ctx.show,
    transition: "dialog-bottom-transition",
    scrollable: "",
    width: "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "order-dialog",
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            class: "dialog-toolbar",
            title: "Pedido"
          }),
          _createVNode(_component_v_list, { lines: false }, {
            default: _withCtx(() => [
              (_ctx.order.title)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    title: "Title",
                    subtitle: _ctx.order.title
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.email)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 1,
                    title: "E-mail",
                    subtitle: _ctx.order.email
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.description_additional)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 2,
                    title: "Informação Adicional",
                    subtitle: _ctx.order.description_additional
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.description_author)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 3,
                    title: "Autores",
                    subtitle: _ctx.order.description_author
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.description_holder)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 4,
                    title: "Titulares",
                    subtitle: _ctx.order.description_holder
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.file_hash)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 5,
                    title: "Hash",
                    subtitle: _ctx.order.file_hash
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.file_filename)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 6,
                    title: "Arquivo",
                    subtitle: _ctx.order.file_filename
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.file_category)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 7,
                    title: "Categoria",
                    subtitle: _ctx.order.file_category
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.file_size)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 8,
                    title: "Tamanho",
                    subtitle: _ctx.order.file_size
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.file_type)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 9,
                    title: "Tipo",
                    subtitle: _ctx.order.file_type
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.coupon)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 10,
                    title: "Cupom",
                    subtitle: _ctx.order.coupon
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.generated_at)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 11,
                    title: "Gerado em",
                    subtitle: _ctx.formatDatetime(_ctx.order.generated_at)
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.paid_at)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 12,
                    title: "Pago em",
                    subtitle: _ctx.formatDatetime(_ctx.order.paid_at)
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.expired_at)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 13,
                    title: "Expirado em",
                    subtitle: _ctx.formatDatetime(_ctx.order.expired_at)
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.delivered_at)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 14,
                    title: "Entregue em",
                    subtitle: _ctx.formatDatetime(_ctx.order.delivered_at)
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true),
              (_ctx.order.canceled_at)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 15,
                    title: "Cancelado em",
                    subtitle: _ctx.formatDatetime(_ctx.order.canceled_at)
                  }, null, 8, ["subtitle"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "primary",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Fechar ")
                ]),
                _: 1
              }),
              (_ctx.order.paid_at)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "primary",
                    variant: "text",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmSendCertificate()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Reenviar Certificado ")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_v_snackbar, {
        timeout: 4500,
        modelValue: _ctx.snackbarState,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.snackbarState) = $event)),
        color: _ctx.snackbarColor
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbarTxt), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"]),
      _createVNode(_component_ConfirmBox, {
        modelValue: _ctx.confirmBoxCertificate,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.confirmBoxCertificate) = $event)),
        title: "Reenviar Certificado",
        text: "Confirmar reenvio de certificado?",
        onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelSendCertificate())),
        onConfirm: _cache[6] || (_cache[6] = ($event: any) => (_ctx.sendCertificate()))
      }, {
        default: _withCtx(() => [
          (!_ctx.order.transaction?.invoice)
            ? (_openBlock(), _createBlock(_component_v_text_field, {
                key: 0,
                type: "text",
                class: "mt-5",
                label: "Número da transação",
                variant: "outlined",
                color: "primary",
                modelValue: _ctx.invoice,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.invoice) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["value"]))
}