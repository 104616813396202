import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20e64f95"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-no-wrap" }
const _hoisted_2 = { class: "column-text" }
const _hoisted_3 = { class: "text-no-wrap" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "d-flex flex-nowrap" }
const _hoisted_6 = { class: "d-flex justify-center w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_OrderDialog = _resolveComponent("OrderDialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_PageContent = _resolveComponent("PageContent")!

  return (_openBlock(), _createBlock(_component_PageContent, {
    title: _ctx.title,
    breadcrumbs: _ctx.breadcrumbs
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_v_pagination, {
          color: "primary",
          length: _ctx.numberPages,
          "total-visible": 5,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeCurrentPage($event)))
        }, null, 8, ["length"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "order-list",
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { class: "order-list-toolbar" }, {
            default: _withCtx(() => [
              _createVNode(_component_Searchbar, {
                onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.orderSearch($event)))
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_tooltip, {
                location: "right",
                text: "Atualizar Pedidos"
              }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({
                    icon: "mdi-refresh",
                    variant: "tonal"
                  }, props, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadOrders()))
                  }), null, 16)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_data_table, {
            headers: _ctx.headers,
            items: _ctx.orders,
            "items-per-page": -1
          }, {
            body: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: order.id
                }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formatDatetime(order.created_at)), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(order.title), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(order.email), 1)
                  ]),
                  _createElementVNode("td", null, _toDisplayString(order.transaction?.invoice), 1),
                  _createElementVNode("td", _hoisted_4, [
                    (order.paid_at)
                      ? (_openBlock(), _createBlock(_component_v_tooltip, {
                          key: 0,
                          location: "top",
                          text: _ctx.formatDatetime(order.paid_at)
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              icon: "mdi-checkbox-marked",
                              color: "success"
                            }, props), null, 16)
                          ]),
                          _: 2
                        }, 1032, ["text"]))
                      : _createCommentVNode("", true),
                    (!order.paid_at)
                      ? (_openBlock(), _createBlock(_component_v_icon, {
                          key: 1,
                          icon: "mdi-checkbox-blank",
                          color: "grey"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_v_btn, {
                        icon: "mdi-eye-outline",
                        size: "small",
                        variant: "text",
                        onClick: ($event: any) => (_ctx.openOrder(order))
                      }, null, 8, ["onClick"])
                    ])
                  ])
                ]))
              }), 128))
            ]),
            bottom: _withCtx(() => []),
            _: 1
          }, 8, ["headers", "items"])
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_OrderDialog, {
        modelValue: _ctx.orderDialog,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.orderDialog) = $event)),
        order: _ctx.currentOrder,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeOrderDialog()))
      }, null, 8, ["modelValue", "order"]),
      _createVNode(_component_v_snackbar, {
        timeout: 4500,
        modelValue: _ctx.snackbarState,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.snackbarState) = $event)),
        color: _ctx.snackbarColor
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbarTxt), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }, 8, ["title", "breadcrumbs"]))
}