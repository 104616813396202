
import AuthService from '@/services/AuthService';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LoginView',
	data() {
		return {
			logo: require('@/assets/images/logo.webp'),
			email: '',
			password: '',
			snackbar: false,
			message: '',
			loading: false
		}
	},
	methods: {
		submit() {
			if (!this.validate()) return;
			this.loading = true;
			const data = {
				email: this.email,
				password: this.password,
				access_level: 'admin'
			}
			AuthService.login(data)
				.then(res => {
					this.loading = false;
					AuthService.setToken(res.data.token);
					AuthService.setUser(res.data.user);
					this.$router.push('/home');
				})
				.catch(res => {
					this.loading = false;
					console.log(res)
					if (res.status == 400) {
						this.message = res.data.message;
						this.snackbar = true;
					}
				});
		},
		validate() {
			if (this.email.length == 0) {
				this.message = 'E-mail é obrigatório. Por favor, informe o e-mail.';
				this.snackbar = true;
				return false;
			}
			if (this.password.length == 0) {
				this.message = 'Senha é obrigatória. Por favor, informe a senha';
				this.snackbar = true;
				return false;
			}
			return true;
		}
	}
});
