
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ConfirmBox',
    props: {
        model: Boolean,
        title: String,
        text: String
    }
})
