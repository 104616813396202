import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_component_v_layout, { "full-height": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_navigation_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
        "disable-route-watcher": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { prominent: "" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "v-toolbar__logo",
                src: _ctx.logo
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_list, {
            density: "compact",
            nav: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  link: "",
                  "prepend-icon": item.icon,
                  title: item.title,
                  value: item.value,
                  to: item.to,
                  key: item.value
                }, null, 8, ["prepend-icon", "title", "value", "to"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}