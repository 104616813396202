import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e40d0bcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_toolbar, {
      class: "page-header",
      prominent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
        }),
        _createVNode(_component_v_toolbar_title, null, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.breadcrumbs)
              ? (_openBlock(), _createBlock(_component_v_breadcrumbs, {
                  key: 1,
                  class: "breadcrumbs",
                  items: _ctx.breadcrumbs
                }, {
                  divider: _withCtx(() => [
                    _createVNode(_component_v_icon, { icon: "mdi-chevron-right" })
                  ]),
                  _: 1
                }, 8, ["items"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          icon: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode("mdi-export")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["page-content", _ctx.classObj]),
      id: "page-content",
      style: _normalizeStyle(_ctx.styleObj)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6),
    (_ctx.hideFooter == false)
      ? (_openBlock(), _createBlock(_component_v_footer, {
          key: 0,
          class: "page-footer"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}